import { SafetyDataSheet } from "./types/types";
import { ATLASAPPKEY, ATLASIDENTIFIER, CN } from "./constants";
import { environment } from "./env/env";
import moment from "moment";

export const availableLang =
  environment.validityArea != CN
    ? ["de", "en", "es", "fr", "zh", "ja", "it", "pt"]
    : ["en", "zh"];

export function splitName(name: string): any {
  let tempArray = name.split(" ");
  switch (tempArray.length) {
    case 1: {
      let [tempFirstName] = tempArray;
      return { firstName: tempFirstName, lastName: "" };
    }
    case 2: {
      let [tempFirstName, tempLastName] = tempArray;
      return { firstName: tempFirstName, lastName: tempLastName };
    }
    case 3: {
      let [tempFirstName, tempMiddleName, tempLastName] = tempArray;
      return {
        firstName: `${tempFirstName} ${tempMiddleName}`,
        lastName: tempLastName,
      };
    }
  }
  return;
}

export function replaceHashFromArticleNumber(
  sds: SafetyDataSheet
): SafetyDataSheet {
  sds.section_one.articleNumber = sds.section_one.articleNumber.replace(
    "###",
    " "
  );
  return sds;
}

export function checkLanguagePresent(language: String) {
  const extractedLang = language.split("-")[0] || "";
  return availableLang.find((lang) => extractedLang == lang);
}

function convertMMtoPixel(sizeInMM: number) {
  return (300 * sizeInMM) / 25.4;
}

export function convertPixeltoMM(pixel: number) {
  return (25.4 * pixel) / 300;
}

export const template = {
  big: { width: 155, height: 50 },
  old: { width: 155, height: 25 },
  medium: { width: 100, height: 30 },
  milliseq: { height: 28.5, width: 43.5 },
  small: { width: 29, height: 25 },
};

export function converTemplateMMtoPixel(templateName: string) {
  const dim = template[`${templateName}`];
  const width = convertMMtoPixel(dim.width);
  const height = convertMMtoPixel(dim.height);
  return { width, height };
}

export function calculateSDS_Length(sds: SafetyDataSheet): Number {
  const {
    hazardStatements,
    hazardStatementsSec,
    precautionaryStatements,
    otherHazards,
  } = sds.section_two;
  const {
    response = [],
    storage = [],
    disposal = [],
    prevention = [],
  } = precautionaryStatements;
  let tempHazardState = hazardStatements.toString().replace(/,/g, "");
  let tempHazardStatementsSec = hazardStatementsSec
    .toString()
    .replace(/,/g, "");
  let tempOtherHazards = otherHazards.toString().replace(/,/g, "");
  let tempResponse = response.toString().replace(/,/g, "");
  let tempStorage = storage.toString().replace(/,/g, "");
  let tempDisposal = disposal.toString().replace(/,/g, "");
  let tempPrevention = prevention.toString().replace(/,/g, "");
  let checkLength =
    tempHazardState.length +
    tempHazardStatementsSec.length +
    tempOtherHazards.length +
    tempResponse.length +
    tempStorage.length +
    tempDisposal.length +
    tempPrevention.length;
  return checkLength;
}
// Remove the & or # symbol and added the newline
export function removeSymbolFromText(value: string) {
  if (value.includes("&&&")) {
    return "<br>" + value.replace("&&&", " ");
  }
  if (value.includes("###")) {
    return value.replace("###", "<br>");
  }
  return value;
}
// Join the text with comma seperator
export function textJoinWithComma(arr: any) {
  if (arr?.length) {
    return arr.join(", ");
  }
  return arr.toString();
}
// replace => semicolon to comma
export function semiColonReplaceToComma(str: string, replaceStr:string=", ") {
  return textReplacewithParam(str, replaceStr);
}
// replace => semicolon to NewLine
export function semiColonReplaceToNewLine(str: string, replaceStr:string="<br>") {
  return textReplacewithParam(str, replaceStr);
}
function textReplacewithParam(txtContent:string, replaceStr:string){
  if (txtContent) {
    return txtContent.split(";").join(replaceStr);
  }
  return txtContent;
}
export function checkIsDesktop(deviceDetails:{deviceType:string; os:string}){
  const {deviceType, os} = deviceDetails;
  return deviceType !== "tablet" &&(os === "Mac" || os === "Windows");
}

export function getPlayStoreUrl(){
  const url = "https://play.google.com/store/apps/details?id=";
  let path = environment.region == "ROW" ? "de.merck.merckproductsafety.googleplay" : "com.emd.mymsafety.googleplay";
  return url+path;  
}
export function getAppleStoreUrl(country:string){
  let path = environment.region == "ROW" ? country : "us";
  let id = environment.region == "ROW" ? "id1440030082" : "id1442236510";
  return `https://apps.apple.com/${path}/app/my-m-safety-sds-label/${id}`;
}
export function validateAtlasToken(validity: string) {
  let isTokenExpiried = false;
  const currentTime = moment.utc();
  const duration = moment.duration(
    currentTime.diff(moment.utc(validity, "YYYY-MM-DDHHmm"))
  );
  if (duration.minutes() > 0) {
    isTokenExpiried = true;
  }
  return isTokenExpiried;
}
export function generateTokenTimeStamp() {
  //let t = '2024/12/12 10:48', f="YYYY/MM/DD HH:mm"
  const cUTCMin = Number(moment.utc().format('mm'));
  const eMin = (15 - (cUTCMin % 15)) % 15;
  return moment.utc().add(eMin,'minutes').format('YYYY-MM-DDHHmm');    
}
export function getAtlasToken(ValidityTime:string) {
  let params = {
    data: btoa(ATLASAPPKEY + ValidityTime),
    identifier: ATLASIDENTIFIER,
    appKey: ATLASAPPKEY
  };  
  let options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(params)
  };  
  let baseurl = environment.baseUrl.replace("api", "v1/getHmacToken");
  return fetch(baseurl, options)
    .then(response => response.json())
    .catch(error => console.error('Error:', error));
}
export function regenerateAtlasToken(tokenData: any) {
  return {
    validity: tokenData.validity,
    token: `HMAC ${ATLASAPPKEY}:${tokenData.token}`,
  };
}
