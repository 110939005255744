import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { from, Observable } from "rxjs";
import { switchMap } from "rxjs/operators";
import { generateTokenTimeStamp, getAtlasToken, regenerateAtlasToken, validateAtlasToken } from "../helperFunction";

@Injectable({
  providedIn: "root",
})
export class AuthInterceptor implements HttpInterceptor {
  constructor() {}
 /* intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      // Get the auth header from the service.
      let authReq;

      // TODO add token for authenticated users
      authReq = req.clone({ headers: req.headers.set('Content-Type', 'application/json') });

      return next.handle(authReq);
  }*/
  atlasToken: {
    token: string;
    validity: string;
  };
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let authReq;
    authReq = req.clone({
      headers: req.headers.set("Content-Type", "application/json"),
    });
    const path = ["getHmacToken"];
    if (
      req.url.includes("atlasdev") &&
      !path.some((p) => req.url.includes(p))
    ) {
      if (this.atlasToken && !validateAtlasToken(this.atlasToken.validity)) {
        const clonedRequest = req.clone({
          setHeaders: {
            Authorization: `${this.atlasToken.token}`,
            "Content-Type": "application/json",
          },
        });
        return next.handle(clonedRequest);
      }     
      const tm = generateTokenTimeStamp(); //Validity Period
      return from(getAtlasToken(tm)).pipe(
        switchMap((tokenRes) => {
          tokenRes.validity = tm;
          const newAtlasToken = regenerateAtlasToken(tokenRes);
          this.atlasToken = newAtlasToken;
          const clonedRequest = req.clone({
            setHeaders: {
              Authorization: `${newAtlasToken.token}`,
              "Content-Type": "application/json",
            },
          });
          return next.handle(clonedRequest);
        })
      );
    }
    return next.handle(authReq);
  }
}
