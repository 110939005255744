import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  Article,
  SafetyDataSheet,
  ScannedArticleDetails,
  SearchResult,
} from "../types/types";
import { ArticleLabel } from "./label-scanner/label-scanner.service";
import { Observable, of, Subject } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { environment } from "../env/env";
import { SettingsService } from "./settings.service";
import moment from "moment";

@Injectable({
  providedIn: "root",
})
export class SDSService {
  constructor(private http: HttpClient, private settings: SettingsService) {}

  private apiRoot = "/safetydata";
  private filterVerboseRoot = "/filterVerbose";
  private searchVerboseRoot = "/searchVerbose";
  private rating = "PUBLIC";
  private systems = ["P24", "NEX"];
  public defaultSystemsIndex = 1;
  public search$ = new Subject();
  public searchFocus$ = new Subject();
  public renderFooter = new Subject();
  public showScroll$ = new Subject();
  public showScanner$ = new Subject();
  public atlasToken$ = new Subject();
  private atlasAppKey = "441c8fdb2acab27405bf4d9c96a535fc";
  private atlasIdentifier = "0cxTYJ9Ayo";

  public searchArticleByMaterialNumberAndLimit(
    selectedValue: null | string,
    materialNumber: string,
    limit: number,
    offset: number
  ): Observable<SearchResult> {
    let validityArea = this.settings.validityArea || environment.validityArea;
    let language = this.settings.language || environment.language;
    let verboseRoot = this.searchVerboseRoot;
    if (selectedValue) {
      verboseRoot = this.filterVerboseRoot;
    }
    let url = `${this.getAtlasBasePath(
      verboseRoot
    )}/${validityArea}/${language}?q=${materialNumber}&limit=${limit}&offset=${offset}`;
    if (selectedValue) {
      url += `&filterBy=${selectedValue}`;
    }
    return this.http.get<SearchResult>(url).pipe(
      map((gur) => {
        return this.filterP24(gur);
      })
    );
  }

  public filterArticleByMaterialNumberAndLimit(
    selectedValue: String,
    materialNumber: string,
    limit: number,
    offset: number
  ): Observable<SearchResult> {
    let validityArea = this.settings.validityArea || environment.validityArea;
    let language = this.settings.language || environment.language;
    let verboseRoot = this.searchVerboseRoot;
    if (selectedValue) {
      verboseRoot = this.filterVerboseRoot;
    }
    let url = `${this.getAtlasBasePath(
      verboseRoot
    )}/${validityArea}/${language}?q=${materialNumber}&limit=${limit}&offset=${offset}`;
    if (selectedValue) {
      url += `&filterBy=${selectedValue}`;
    }
    return this.http.get<SearchResult>(url);
  }

  searchArticleByLabelAndLimit(
    articleLabel: ArticleLabel,
    limit: number,
    offset: number
  ): Observable<SearchResult> {
    return this.searchArticleByMaterialNumberAndLimit(
      null,
      articleLabel.materialNumber,
      limit,
      offset
    ).pipe(
      map((searchResult) => {
        searchResult.results = searchResult.results.map((article) => {
          article.batchNumber = articleLabel.batchNumber;
          return article;
        });
        return searchResult;
      })
    );
  }

  searchArticleByMaterialNumberByLanguageCountry(
    materialNumber: string,
    country: string,
    language: string
  ): Observable<SearchResult> {
    const limit = 1;
    const offset = 0;
    let tempCountry = country || this.settings.validityArea;
    let tempLanguage = language || this.settings.language;

    return this.http.get<SearchResult>(
      `${this.getAtlasBasePath(
        this.searchVerboseRoot
      )}/${tempCountry}/${tempLanguage}?q=${materialNumber}&limit=${limit}&offset=${offset}`
    );
  }

  loadFullSDSDataByMaterialNumberBySystemCountryLanguage(
    materialNumber: string,
    system: string,
    country: string,
    language: string
  ): Observable<SafetyDataSheet> {
    let tempCountry = country || this.settings.validityArea;
    let tempLanguage = language || this.settings.language;
    return this.http.get<SafetyDataSheet>(
      `${this.getAtlasBasePath(
        `/${system}`
      )}/${tempCountry}/${tempLanguage}/${materialNumber}`
    );
  }

  loadFullSDSDataByMaterialNumberAndSystem(
    materialNumber: string,
    system: string
  ): Observable<SafetyDataSheet> {
    return this.http.get<SafetyDataSheet>(
      `${this.getAtlasBasePath(`/${system}`)}/${this.settings.validityArea}/${
        this.settings.language
      }/${materialNumber}`
    );
  }

  loadCurrentArticleDetails(
    selectedArticle: Article
  ): Observable<SafetyDataSheet> {
    return this.loadFullSDSDataByMaterialNumberAndSystem(
      selectedArticle.materialNumber,
      selectedArticle.system
    );
  }

  loadScannedArticleDetails(
    articleLabel: ArticleLabel
  ): Observable<ScannedArticleDetails> {
    return this.loadArticleDetailsWithoutSystem(
      articleLabel,
      this.defaultSystemsIndex
    );
  }

  private loadArticleDetailsWithoutSystem(
    articleLabel: ArticleLabel,
    systemNumber: number
  ): Observable<ScannedArticleDetails> {
    return this.loadFullSDSDataByMaterialNumberAndSystem(
      articleLabel.materialNumber,
      this.systems[systemNumber]
    ).pipe(
      map((response) => {
        return {
          article: {
            materialNumber: articleLabel.materialNumber,
            articleNumber: articleLabel.articleNumber,
            batchNumber: articleLabel.batchNumber,
            articleName: response.header.articleName,
            system: this.systems[systemNumber],
            substance: null,
            casNumber: response.section_one.cas,
          },
          sds: response,
        };
      }),
      catchError((error) => {
        if (this.systems.length > systemNumber + 1) {
          return this.loadArticleDetailsWithoutSystem(
            articleLabel,
            systemNumber + 1
          );
        }
        return of(null);
      })
    );
  }

  showFooter(footerVal) {
    this.renderFooter = footerVal;
  }

  filterP24(gur: SearchResult): SearchResult {
    const temp = { ...gur };
    temp.results = temp.results.filter((t) => t.system === "NEX");
    return temp;
  }

  getIFUData(
    artNum: string,
    brandName: string,
    country: string,
    language: string,
    validRegion: string
  ): Observable<SafetyDataSheet> {
    // let useBrand = "MM";
    //changing to Environment to support IFU
    let langUsed: string;
    if (environment.region == "US") {
      langUsed = "en";
    } else {
      langUsed = environment.language.toLowerCase();
    }
    //Revised according to the MMS-2020 story
    if (country === "EU") {
      validRegion = "DE";
    }
    // alert(`${environment.IFUDocAPIBaseURL}${artNum}${brandName}&locale=${langUsed}_${country}&region=${validRegion}`);
    //  return this.http.get<SafetyDataSheet>(`https://searchcatalog-up.milliporesigma.io/catalog/v2/sial/products?pId=116124MM&locale=en_US&region=US`);
    //  return this.http.get<SafetyDataSheet>(`https://searchcatalog-up.milliporesigma.io/catalog/v2/sial/products?pId=T1503SIGMA&locale=de_EU&region=DE`);
    // return this.http.get<SafetyDataSheet>(`https://mymsafety-dev.merckgroup.com/api/v1/product/sial/search?product=118752MM&locale=en_IN&region=IN`);
    return this.http.get<SafetyDataSheet>(
      `${environment.IFUDocAPIBaseURL}${artNum}${brandName}&locale=${langUsed}_${country}&region=${validRegion}`
    );

    // return this.http.get<SafetyDataSheet>(`https://searchcatalog-up.milliporesigma.io/catalog/v2/sial/products?pId=${artNum}${brandName}&locale=${country}_${language}&region=${validRegion}`);
  }
  getAtlasBasePath(verboseRoot: string) {
    return `${environment.baseUrl}/${environment.apiVersion}${this.apiRoot}${verboseRoot}/${this.rating}`;
  }
  getAtlasToken(ValidityTime: string) {
    let params = {
      data: btoa(this.atlasAppKey + ValidityTime),
      identifier: this.atlasIdentifier,
      appKey: this.atlasAppKey,
    };
    let options = {};
    options["headers"] = new HttpHeaders({
      "Content-Type": "application/json",
    });
    let baseurl = environment.baseUrl.replace("api", "v1/getHmacToken");
    return this.http.post<any>(baseurl, params, options);
  }
  resetAtlasToken(tokenData: any) {
    return {
      validity: tokenData.validity,
      token: `HMAC ${this.atlasAppKey}:${tokenData.token}`,
    };
  }
  validateAtlasToken(validity: string) {
    let isTokenExpiried = false;
    const currentTime = moment.utc();
    const duration = moment.duration(
      currentTime.diff(moment.utc(validity, "YYYY-MM-DDHHmm"))
    );
    if (duration.minutes() > 0) {
      isTokenExpiried = true;
    }
    return isTokenExpiried;
  }
  generateTokenTimeStamp() {
    //let t = '2024/12/12 10:48', f="YYYY/MM/DD HH:mm"
    const cUTCMin = Number(moment.utc().format('mm'));
    const eMin = (15 - (cUTCMin % 15)) % 15;
    return moment.utc().add(eMin,'minutes').format('YYYY-MM-DDHHmm');    
  }
}
